"use client"

import { useEffect, useState } from "react"
import { Loader2 } from "lucide-react"

import { ImageSlider } from "@/components/ui/image-slider"
import ProductCard from "@/components/supervvisor/supervvisor-product-card"

interface MarketingFeature {
  name: string
}

interface Price {
  id: string
  currency: string
  unit_amount: number
  recurring: { interval: string }
}

interface Product {
  id: string
  name: string
  description: string
  images: string[]
  marketing_features?: MarketingFeature[]
  metadata: {
    category?: string
    agents_available?: string
    sites?: string
    current_agent_actions?: string
  }
  prices: Price[]
  isSubscribed: boolean
  capabilities?: { keyword: string; description: string }[]
  active: boolean
}

const sliderImages = [
  {
    src: "/banner/banner2.png", // Path relative to public directory
    alt: "Slide 1",
  },
  // {
  //   src: "/banner/slider-2.jpg", // Path relative to public directory
  //   alt: "Slide 2"
  // }
]
interface HomeProps {
  session: {
    user?: {
      email?: string
      sub?: string
    } | null
  } | null
}
export default function Home({ session }: HomeProps) {
  const [featuredProducts, setFeaturedProducts] = useState<Product[]>([])
  const [products, setProducts] = useState<{ product: Product }[]>([])
  const [productCount, setProductCount] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [subscribeProducts, setSubscribeProducts] = useState<string[]>([])
  const [cancelSubProducts, setCancelSubProducts] = useState<string[]>([])
  const [paymentPendingSubProducts, setPaymentPendingSubProducts] = useState<
    string[]
  >([])
  useEffect(() => {
    const fetchFeaturedProducts = async () => {
      try {
        const searchMetadata =
          "metadata['is_featured']:'true' AND active:'true'"
        const recordLimit = 6

        const response = await fetch(
          `/api/stripe/products?metadata=${searchMetadata}&limit=${recordLimit}`
        )
        const data = await response.json()
        let featuredProductsData = data.data || []

        if (featuredProductsData.length < 6 && products.length > 0) {
          let displayFeaturedProducts = [...featuredProductsData]
          let productIndex = 0

          while (
            displayFeaturedProducts.length < 6 &&
            productIndex < products.length
          ) {
            const product = products[productIndex]?.product

            if (
              product &&
              !displayFeaturedProducts.some((fp) => fp.id === product.id) &&
              product.active
            ) {
              displayFeaturedProducts.push(product)
            }
            productIndex++
          }
          setFeaturedProducts(displayFeaturedProducts)
        } else {
          setFeaturedProducts(featuredProductsData)
        }
        if (session) {
          fetchSubscriptions()
        } else {
          setIsLoading(false)
        }
      } catch (error) {
        console.error("Error fetching featured products:", error)
        setFeaturedProducts([])
      }
    }

    if (products.length > 0) {
      fetchFeaturedProducts()
    }
  }, [products, session])

  useEffect(() => {
    fetchProducts()
  }, [])

  const fetchProducts = async () => {
    try {
      const recordLimit = 100

      const response = await fetch(`/api/stripe/products`)
      const data = await response.json()
      if (data.data) {
        setProductCount(data.data.length)
        setProducts(data.data)
      } else {
        setProductCount(0)
      }
    } catch (error) {
      console.error("Error fetching products:", error)
      setProductCount(0)
    } finally {
    }
  }
  const fetchSubscriptions = async () => {
    try {
      setIsLoading(true)
      const response = await fetch("/api/stripe/subscription")
      const subscriptions = await response.json()
      const productIdsSet = new Set()
      const cancelSubProductIdsSet = new Set()
      const paymentPendingSubProductIdsSet = new Set()
      // Extract product IDs from subscription items
      subscriptions.forEach((subscription: any) => {
        productIdsSet.add(subscription.stripe_product_id)
        if (subscription.cancel_at && subscription.cancel_at !== null) {
          cancelSubProductIdsSet.add(subscription.stripe_product_id)
        }
        if (
          subscription.subscription_status === "past_due" ||
          subscription.subscription_status === "incomplete"
        ) {
          paymentPendingSubProductIdsSet.add(subscription.stripe_product_id)
        }
      })
      const productIds = Array.from(productIdsSet) as string[] // Convert Set to Array and assert type
      setSubscribeProducts(productIds) // Set the filtered products
      setCancelSubProducts(Array.from(cancelSubProductIdsSet) as string[]) // Set the filtered products
      setPaymentPendingSubProducts(
        Array.from(paymentPendingSubProductIdsSet) as string[]
      )
    } catch (error) {
      console.error("Error fetching products:", error)
      //setFeaturedProducts([])
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      {/* Image Slider */}
      <div className="banner-container relative -mx-4 -mt-4 mb-6">
        <ImageSlider
          images={sliderImages}
          className="aspect-[1660/300] h-auto min-h-[250px] w-full"
        />
        <div className="absolute inset-0 flex flex-col items-center justify-center px-4 text-center text-white">
          <h1 className="mb-4 max-w-2xl text-3xl font-bold text-primary md:text-4xl lg:text-5xl">
            Workforce Empowerment With AI Powered Apps
          </h1>
          <p className="max-w-2xl px-4 text-sm text-black md:text-base lg:text-lg">
            Simplify complex tasks, streamline actions across different systems,
            and increase productivity with partner-built agents, actions, and AI
            apps.
          </p>
        </div>
      </div>
      {/* Featured products */}
      <div className="flex flex-1 flex-grow flex-col gap-4 lg:gap-6">
        <div
          className="grid grid-cols-1 gap-4 sm:gap-10 md:mx-32"
          style={{
            gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))",
          }}
        >
          {isLoading ? (
            <div className="col-span-full flex items-center justify-center p-3">
              <Loader2 className="h-8 w-8 animate-spin" />
              <span className="ml-2">Loading products...</span>
            </div>
          ) : featuredProducts.length > 0 ? (
            featuredProducts.map((product) => (
              <ProductCard
                key={product.id}
                product={product}
                productUrl={`/products/${product.id}`}
                isSubscribed={subscribeProducts.includes(product.id)}
                isCancelled={
                  cancelSubProducts.includes(product.id) ? true : false
                }
                isPaymentPending={
                  paymentPendingSubProducts.includes(product.id) ? true : false
                }
              />
            ))
          ) : (
            <div className="col-span-full text-center">
              <h2 className="mb-2 text-2xl font-bold">
                We&apos;re working on it!
              </h2>
              <p className="text-gray-600">
                We&apos;re bringing more products to the marketplace soon. Check
                back later.
              </p>
            </div>
          )}
        </div>
        {/* View All Products Button */}
        {!isLoading && productCount && productCount > 6 && (
          <div className="mt-4 flex justify-center">
            <button
              onClick={() => (window.location.href = "/products")}
              className="hover:bg-primary-600 rounded-lg bg-primary px-4 py-2 text-white"
            >
              View All
            </button>
          </div>
        )}

        {/* About Us Section */}
        {/* <section className="my-6 md:my-8 hidden">
        <div className="mx-auto max-w-4xl">
          <h2 className="mb-4 text-3xl font-bold text-primary dark:text-white">
            About Us
          </h2>
          <div className="prose max-w-none dark:prose-invert">
            <p className="mb-6 text-lg leading-relaxed text-gray-600 dark:text-gray-300">
              We are dedicated to providing innovative solutions that help
              organizations streamline their operations and achieve their
              goals. Our platform combines powerful tools with an intuitive
              interface to deliver exceptional value to our clients.
            </p>
            <div className="mt-8 grid gap-6 md:grid-cols-3">
              <div className="flex flex-col items-center text-center">
                <div className="mb-4 flex h-16 w-16 items-center justify-center rounded-full bg-blue-100 dark:bg-blue-900">
                  <svg
                    className="h-8 w-8 text-blue-600 dark:text-blue-300"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 10V3L4 14h7v7l9-11h-7z"
                    ></path>
                  </svg>
                </div>
                <h3 className="mb-2 text-xl font-semibold">
                  Fast & Efficient
                </h3>
                <p className="text-gray-600 dark:text-gray-400">
                  Optimized performance for seamless operations
                </p>
              </div>
              <div className="flex flex-col items-center text-center">
                <div className="mb-4 flex h-16 w-16 items-center justify-center rounded-full bg-green-100 dark:bg-green-900">
                  <svg
                    className="h-8 w-8 text-green-600 dark:text-green-300"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                    ></path>
                  </svg>
                </div>
                <h3 className="mb-2 text-xl font-semibold">
                  Secure & Reliable
                </h3>
                <p className="text-gray-600 dark:text-gray-400">
                  Enterprise-grade security for your peace of mind
                </p>
              </div>
              <div className="flex flex-col items-center text-center">
                <div className="mb-4 flex h-16 w-16 items-center justify-center rounded-full bg-purple-100 dark:bg-purple-900">
                  <svg
                    className="h-8 w-8 text-purple-600 dark:text-purple-300"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 10V3L4 14h7v7l9-11h-7z"
                    ></path>
                  </svg>
                </div>
                <h3 className="mb-2 text-xl font-semibold">Customizable</h3>
                <p className="text-gray-600 dark:text-gray-400">
                  Tailored solutions to meet your unique needs
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
        {/* About us section */}
      </div>
    </>
  )
}
